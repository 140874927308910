<ul class="root" [ngStyle]="{color: store?.storeProfile?.primaryTextColor}">
  <li>
    <a routerLink="/store/{{store?.handle}}/orders" class="hover-underline-animation">
      {{ 'MENU.myPurchases' | translate }}
    </a>
    <div *ngIf="showMyOrdersBadge" class="menu-item-badge"></div>
  </li>
  <li *ngIf="(customerService.isSignedIn$ | async)">
    <a class="logout hover-underline-animation" (click)="logout()">
      {{ 'LOGIN.signOut' | translate }}
    </a>
  </li>
  <li *ngIf="!(customerService.isSignedIn$ | async) && loginEnabled">
    <a class="logout hover-underline-animation" (click)="login()">
      {{ 'LOGIN.signIn' | translate }}
    </a>
  </li>
  <li class="sub-menu">
    <a class="hover-underline-animation"
       [href]="store?.storeChain?.privacyPolicyUrl ?? '/store/' + store?.handle + '/privacy-policy'"
       [target]="store?.storeChain?.privacyPolicyUrl ? '_blank' : '_self'">
      {{ 'MENU.privacyPolicy' | translate }}
    </a>
    <a *ngIf="store?.storeChain?.termsAndConditionsUrl"
       class="hover-underline-animation"
       [href]="store?.storeChain?.termsAndConditionsUrl"
       target="_blank">
      {{ 'MENU.termsAndConditions' | translate }}
    </a>
  </li>
</ul>
