import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {StoreProfile} from '../../domain/models/store/store-profile';
import {Store} from '../../domain/models/store/store';
import {StoreService} from '../../domain/store.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-quantity-confirm',
  templateUrl: './quantity-confirm.dialog.html',
  styleUrls: ['./quantity-confirm.dialog.sass'],
})
export class QuantityConfirmDialog implements OnInit {
  profile?: StoreProfile;
  store?: Store;
  @Input() quantity?: number;
  @Output() quantitySubmitted = new EventEmitter<number>();
  private readonly initialValue?: number;
  value = 0;

  constructor(
    private storeService: StoreService,
    public dialogRef: MatDialogRef<QuantityConfirmDialog>,
    @Inject(MAT_DIALOG_DATA) public data?: number,
  ) {
    this.initialValue = data;
    if (this.initialValue != null) {
      this.value = this.initialValue;
    }
  }

  async ngOnInit(): Promise<void> {
    if (this.storeService.storeId != undefined) {
      this.store = await this.storeService.getStore(this.storeService.storeId);
      this.profile = this.store.storeProfile;
    }
  }

  removeProduct() {
    this.dialogRef.close(0);
  }

  onSubmit(input: string) {
    const quantity = Number.parseInt(input);
    if (quantity >= 0) {
      this.quantitySubmitted.emit(quantity);
    } else {
      this.quantitySubmitted.emit(this.quantity = 1);
    }
  }

  addProduct(input: string) {
    const quantity = Number(input);
    this.dialogRef.close(quantity);
  }
}
