import {Component, ElementRef, HostListener, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AppConfig} from '../../app.config';
import {BehaviorSubject, interval, Subject, Subscription} from 'rxjs';
import {Point, Rectangle, ScannerProvider, ScanResult} from '../../domain/scanner.service';

declare const CortexDecoder: any;

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.sass'],
})
export class ScannerComponent implements OnInit, OnDestroy, ScannerProvider {
  @ViewChild('video') videoElement?: ElementRef;

  videoResolution?: Resolution;
  cameraResolution?: Resolution;

  screenHeight: number = 0;
  screenWidth: number = 0;

  private cachedResults = new Map<String, TimestampedScanResult>();

  scanResults = new Subject<ScanResult>();
  initializing = false;
  initialized = false;
  decoderInitialized = new BehaviorSubject(false);
  isScanning = false;

  tutorialText?: string;

  center?: Point;

  showAnchors = false; // for debugging

  defaultRect?: Rectangle;
  target?: Rectangle;

  private intervalSubscription?: Subscription;
  targetIntent?: TargetIntent;

  isActive: boolean = false;

  constructor(private ngZone: NgZone) {
    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(_?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;

    if (this.videoElement) {
      this.onLoadStart();
    }

    const isPhone = window.innerWidth < 500;
    const halfScannerWidth = 168 / 2;
    const middleWidth = this.screenWidth / 2;
    const middleHeight = (isPhone ? this.screenHeight : 720) / 2;

    this.defaultRect = {
      TopLeft: {
        X: middleWidth - halfScannerWidth,
        Y: middleHeight - halfScannerWidth,
      },
      TopRight: {
        X: middleWidth + halfScannerWidth,
        Y: middleHeight - halfScannerWidth,
      },
      BottomLeft: {
        X: middleWidth - halfScannerWidth,
        Y: middleHeight + halfScannerWidth,
      },
      BottomRight: {
        X: middleWidth + halfScannerWidth,
        Y: middleHeight + halfScannerWidth,
      },
    };
  }

  async ngOnInit() {
    CortexDecoder.CDDecoder.setDecoding(false);
    const decoderStatus = await CortexDecoder.CDDecoder.init('./assets/');

    if (decoderStatus == undefined) {
      throw new CortexDecoderException(new Error('Failed to initialize CDDecoder'));
    }

    const licenseStatus = await CortexDecoder.CDLicense.activateLicense(AppConfig.scannerLicense);

    if (licenseStatus?.status != 'ACTIVATED') {
      throw new CortexDecoderException(new Error(licenseStatus?.message ?? 'Failed to active license'));
    }

    CortexDecoder.CDDecoder.decoderTimeLimit = 10;
    CortexDecoder.CDSymbology.QR.polarity.CDQRPolarityLightOnDark = true;
    CortexDecoder.CDSymbology.QR.polarity.CDQRPolarityDarkOnLight = true;

    this.decoderInitialized.next(true);
    this.decoderInitialized.complete();
  }

  async initialize() {
    if (this.initializing) {
      return;
    }

    try {
      this.initializing = true;
      await CortexDecoder.CDCamera.init();
      this.initializing = false;
      console.log('CDCamera initialized');
    } catch (error) {
      this.initializing = false;
      throw new CortexDecoderException(error);
    }

    this.initialized = true;
  }

  async ngOnDestroy() {
    await this.stopScan();
  }

  async startScan(tutorialText?: string) {
    if (!this.initialized && this.decoderInitialized.value) {
      try {
        await this.initialize();
      } catch (error) {
        if (error.message == 'Get User Media not supported') {
          console.warn('Camera permission denied');
          return;
        } else {
          throw error;
        }
      }
    }

    if (!this.initialized) {
      return;
    }

    if (tutorialText) {
      this.tutorialText = tutorialText;
    }

    if (this.isScanning) {
      return;
    }

    try {
      await CortexDecoder.CDCamera.setCameraPosition('BACK');
    } catch (error) {
      await this.trySettingsCameraManually();
    }

    this.isScanning = true;

    try {
      await CortexDecoder.CDCamera.startPreview((result: ScanResults) => {
        result.results.forEach(value => {
          // store results in temporary cache to be used on interval
          this.cachedResults.set(value.barcodeData, {...value, timestamp: Date.now()});
        });
      });
    } catch (error) {
      this.isScanning = false;

      throw new CortexDecoderException(error);
    }

    this.intervalSubscription?.unsubscribe();
    const intervalTimeMilliSeconds = 100;
    // start interval subscription to check cached scan-results for changes
    this.intervalSubscription = interval(intervalTimeMilliSeconds).subscribe(_ => {
      if (this.cachedResults.size > 0) {
        const center = {
          X: this.videoResolution!.width * 0.5,
          Y: this.videoResolution!.height * 0.5
        };
        const closestToCenter = Array.from(this.cachedResults.values())
          .map(value => {
            const valueCenter = ScannerComponent.getCenter(value.barcodeCoordinates);
            return {
              ...value,
              distanceFromCenter: ScannerComponent.calculateDistance(valueCenter, center)
            };
          })
          .sort((a, b) => {
            if (a.distanceFromCenter < b.distanceFromCenter) {
              return -1;
            }
            return 1;
          })[0];

        // check if closest target is too far from center
        if (closestToCenter.distanceFromCenter > this.videoResolution!.height * 0.3) {
          // if closest target is too far from center we reset all values
          this.ngZone.run(_ => {
            this.target = undefined;
            this.center = undefined;
            this.targetIntent = undefined;
          });
        } else {
          // move visible target
          this.moveTarget(closestToCenter);

          // check if current target is the closest to center
          if (this.targetIntent && this.targetIntent.barcodeData == closestToCenter.barcodeData) {
            const isLocked = this.targetIntent.percent > 0.2;
            const current = ScannerComponent.getCenter(this.targetIntent.barcodeCoordinates);
            const closest = ScannerComponent.getCenter(closestToCenter.barcodeCoordinates);
            const distanceToCurrent = ScannerComponent.calculateDistance(current, closest);
            // if distance is too far from center and percent is less than 80% we reset
            // if percent is greater than 80% the target is locked
            if (distanceToCurrent > 40 && !isLocked) {
              this.targetIntent.percent = 0;
            }
            if (this.targetIntent.percent < 1) {
              const animationInSeconds = .10;
              this.targetIntent.barcodeCoordinates = closestToCenter.barcodeCoordinates;
              const m = this.targetIntent.percent >= 0.6 ? 2 : 1;
              this.targetIntent.percent += m * (intervalTimeMilliSeconds / 1000) * (1 / animationInSeconds);
            } else {
              // locked target is marked as complete and broadcast to listeners (only once)
              if (!this.targetIntent.isSent) {
                this.targetIntent.isSent = true;
                this.ngZone.run(_ => {
                  // broadcast
                  this.scanResults.next(this.targetIntent);
                });
              }
            }
          } else {
            // reset target intent
            this.targetIntent = {
              ...closestToCenter,
              percent: 0,
              isSent: false,
            };
          }
        }
      } else {
        // reset all values
        this.ngZone.run(_ => {
          this.target = undefined;
          this.center = undefined;
          this.targetIntent = undefined;
        });
      }
      Array.from(this.cachedResults.values()).forEach(value => {
        // clear old cached results
        if (Date.now() - value.timestamp > 500) {
          this.cachedResults.delete(value.barcodeData);
        }
      });
    });
  }

  private async trySettingsCameraManually() {
    try {
      const cameras = CortexDecoder.CDCamera.getConnectedCameras();

      if (cameras?.length > 0) {
        const current = CortexDecoder.CDCamera.getCamera();
        if (current.id !== cameras[cameras.length - 1].id) {
          // normally the back facing camera
          await CortexDecoder.CDCamera.setCamera(cameras[cameras.length - 1].id);
        }
      }
    } catch (error) {
      throw new CortexDecoderException(error);
    }
  }

  private moveTarget(result: ScanResult) {
    const r = this.scaleCoordinates(result.barcodeCoordinates);
    if (!r) {
      return;
    }

    // find the extreme sides (it ignores rotation)
    let left = Math.min(r.TopLeft.X, r.BottomLeft.X, r.BottomRight.X, r.BottomLeft.X);
    let top = Math.min(r.TopLeft.Y, r.TopRight.Y, r.BottomLeft.Y, r.BottomRight.Y);
    let right = Math.max(r.TopRight.X, r.BottomRight.X, r.TopLeft.X, r.BottomLeft.X);
    let bottom = Math.max(r.BottomLeft.Y, r.BottomRight.Y, r.TopLeft.Y, r.TopRight.Y);

    if (this.target) {
      // move target side only if it exceeds threshold difference from current target
      const sideThreshold = 14;
      if (Math.abs(left - this.target.TopLeft.X) < sideThreshold) {
        left = this.target.TopLeft.X;
      }
      if (Math.abs(top - this.target.TopLeft.Y) < sideThreshold) {
        top = this.target.TopLeft.Y;
      }
      if (Math.abs(right - this.target.TopRight.X) < sideThreshold) {
        right = this.target.TopRight.X;
      }
      if (Math.abs(bottom - this.target.BottomRight.Y) < sideThreshold) {
        bottom = this.target.BottomRight.Y;
      }
    }

    const topLeft = {
      X: left,
      Y: top,
    };
    const topRight = {
      X: right,
      Y: top,
    };
    const bottomLeft = {
      X: left,
      Y: bottom,
    };
    const bottomRight = {
      X: right,
      Y: bottom,
    };

    const center = {
      X: (topLeft.X + topRight.X + bottomRight.X + bottomLeft.X) * 0.25,
      Y: (topLeft.Y + topRight.Y + bottomRight.Y + bottomLeft.Y) * 0.25,
    };

    if (this.center) {
      const deltaX = (center.X - this.center.X);
      const deltaY = (center.Y - this.center.Y);
      const centerDistance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
      const centerDistanceThreshold = 5;
      // move target only if distance exceeds threshold
      if (centerDistance >= centerDistanceThreshold) {
        this.center = center;
        this.target = {
          TopLeft: topLeft,
          TopRight: topRight,
          BottomRight: bottomRight,
          BottomLeft: bottomLeft,
        };
      }
    } else {
      this.center = center;
      this.target = {
        TopLeft: topLeft,
        TopRight: topRight,
        BottomRight: bottomRight,
        BottomLeft: bottomLeft,
      };
    }
  }

  // CortexDecoder throws this event when no camera source could be found after it had already been initialized
  @HostListener('window:restartCamera')
  async restartCamera() {
    // Stop fist in order to prevent infinite window:restartCamera spam, then retry
    await this.stopScan();
    if (this.initialized && !this.initializing) {
      console.log('@window:restartCamera; Restarting scanner');
      this.initialized = false;
      await this.startScan(this.tutorialText);
      this.resumeScan();
    }
  }

  async stopScan() {
    this.tutorialText = undefined;

    if (!this.isScanning) {
      return;
    }

    this.isScanning = false;
    await CortexDecoder.CDCamera.stopCamera();
    this.isActive = false;
    this.intervalSubscription?.unsubscribe();
    console.log('stopped scanning');
  }

  pauseScan() {
    this.tutorialText = undefined;
    CortexDecoder.CDDecoder.setDecoding(false);
    this.isActive = false;
    console.log('paused scanning');
  }

  resumeScan() {
    CortexDecoder.CDDecoder.setDecoding(true);
    this.isActive = true;
    console.log('resumed scanning');
  }

  onVideoLoadStart(_: Event) {
    setTimeout(() => {
      this.onLoadStart();
    }, 1000);
  }

  getIntentWidth(intent: TargetIntent): number {
    const r = this.scaleCoordinates(intent.barcodeCoordinates)!;
    let left = Math.min(r.TopLeft.X, r.BottomLeft.X);
    let right = Math.max(r.TopRight.X, r.BottomRight.X);
    return right - left;
  }

  getIntentHeight(intent: TargetIntent): number {
    const r = this.scaleCoordinates(intent.barcodeCoordinates)!;
    let top = Math.min(r.TopLeft.Y, r.TopRight.Y);
    let bottom = Math.max(r.BottomLeft.Y, r.BottomRight.Y);
    return bottom - top;
  }

  onLoadStart() {
    const vidH = this.videoElement!.nativeElement.videoHeight;
    const vidW = this.videoElement!.nativeElement.videoWidth;

    this.videoResolution = {
      width: vidW,
      height: vidH,
    };

    const elementH = this.videoElement!.nativeElement.clientHeight;
    const elementW = this.videoElement!.nativeElement.clientWidth;

    const heightRatio = elementH / vidH;
    const widthRatio = elementW / vidW;

    if (heightRatio < widthRatio) {
      this.cameraResolution = {
        width: elementW,
        height: vidH * widthRatio,
      };
    } else {
      this.cameraResolution = {
        width: vidW * heightRatio,
        height: elementH,
      };
    }
  }

  // scale cortex coordinates to local coordinates
  private scaleCoordinates(coordinates: Rectangle): Rectangle | undefined {

    if (!this.videoResolution || !this.cameraResolution) {
      return undefined;
    }

    let widthRatio = this.videoResolution.width / this.cameraResolution.width;
    let heightRatio = this.videoResolution.height / this.cameraResolution.height;

    const isPhone = window.innerWidth < 500;

    const xOffset = (window.innerWidth - this.cameraResolution.width) * 0.5;
    const yOffset = isPhone ? (window.innerHeight - this.cameraResolution.height) * 0.5 : 17;

    return {
      TopLeft: {
        X: xOffset + coordinates.TopLeft.X / widthRatio,
        Y: yOffset + coordinates.TopLeft.Y / heightRatio,
      },
      TopRight: {
        X: xOffset + coordinates.TopRight.X / widthRatio,
        Y: yOffset + coordinates.TopRight.Y / heightRatio,
      },
      BottomLeft: {
        X: xOffset + coordinates.BottomLeft.X / widthRatio,
        Y: yOffset + coordinates.BottomLeft.Y / heightRatio,
      },
      BottomRight: {
        X: xOffset + coordinates.BottomRight.X / widthRatio,
        Y: yOffset + coordinates.BottomRight.Y / heightRatio,
      },
    };
  }

  private static calculateDistance(a: Point, b: Point): number {
    const deltaX = (a.X - b.X);
    const deltaY = (a.Y - b.Y);
    return Math.sqrt(deltaX * deltaX + deltaY * deltaY);
  }

  private static getCenter(rect: Rectangle): Point {
    return {
      X: (rect.TopLeft.X + rect.TopRight.X + rect.BottomRight.X + rect.BottomLeft.X) * 0.25,
      Y: (rect.TopLeft.Y + rect.TopRight.Y + rect.BottomRight.Y + rect.BottomLeft.Y) * 0.25,
    };
  }

  @HostListener('window:mockScannerResult', ['$event'])
  __mockResult__(event: CustomEvent) {
    const targetIntent = <TargetIntent>{
      barcodeData: event.detail.barcodeData,
      symbologyName: event.detail.symbologyName,
    };
    this.ngZone.run(() => this.scanResults.next(targetIntent));
  }
}

interface ScanResults {
  results: ScanResult[]
}

type TimestampedScanResult = ScanResult & { timestamp: number };

type TargetIntent = ScanResult & { percent: number, isSent: boolean };

interface Resolution {
  width: number;
  height: number;
}

class CortexDecoderException extends Error {
  constructor(error: any) {
    super(error.message);
    this.name = 'CortexDecoderException';

    // captureStackTrace() is not available in other browsers than Chrome
    if ('captureStackTrace' in Error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Error.captureStackTrace(this, this.constructor);
    } else if ('stack' in error && error.stack) {
      this.stack = error.stack;
    }
  }
}
