import {HttpClient} from '@angular/common/http';
import {Page, Paginated} from './models/paginated';
import {Observable} from 'rxjs';
import {AppConfig} from '../app.config';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Product} from '../domain/models/product/product';
import {Category} from '../domain/models/category/category';

@Injectable({
  providedIn: 'root',
})
export class ProductProvider {

  constructor(private http: HttpClient) {
  }

  getProductByHandle(storeHandle: string, productHandle: string): Observable<Product> {
    return this.http.get<Product>(`${AppConfig.getApiUrl()}/products/${storeHandle}/handle/${productHandle}`,
      {
        headers: {'auth-key': AppConfig.authKey},
      });
  }

  getProductById(storeHandle: string, productId: string): Observable<Product> {
    return this.http.get<Product>(`${AppConfig.getApiUrl()}/products/${storeHandle}/${productId}`,
      {
        headers: {'auth-key': AppConfig.authKey},
      });
  }

  searchProducts(storeHandle: string, query: string, page?: Page, isWeight?: boolean): Observable<Paginated<Product>> {
    return this.http.post<Product[]>(`${AppConfig.getApiUrl()}/products/${storeHandle}/search`,
      {
        query,
        isWeight,
      }, {
        headers: {'auth-key': AppConfig.authKey},
        params: page?.toParams(),
        observe: 'response',
      }).pipe(map(r => Paginated.fromResponse<Product>(r)));
  }

  getCategory(storeHandle: string, categoryId: string): Observable<Category> {
    return this.http.get<Category>(`${AppConfig.getApiUrl()}/products/${storeHandle}/categories/${categoryId}`,
      {
        headers: {'auth-key': AppConfig.authKey},
      });
  }

  getByBarcode(storeHandle: string, barcode: string): Observable<Product> {
    return this.http.get<Product>(`${AppConfig.getApiUrl()}/products/${storeHandle}/barcode/${barcode}`, {
      headers: {'auth-key': AppConfig.authKey},
    });
  }

  getShoppingBag(storeHandle: string, page?: Page): Observable<Paginated<Product>> {
    return this.http.get<Product[]>(`${AppConfig.getApiUrl()}/products/${storeHandle}/shopping-bags`,
      {
        headers: {'auth-key': AppConfig.authKey},
        params: page?.toParams(),
        observe: 'response',
      }).pipe(map(r => Paginated.fromResponse<Product>(r)));
  }
}
