<div class="root">
  <div>
    <div>
      <img class="cart-details-avatar-image" src="assets/images/avatar.svg" alt="avatar image">
      <h5 class="cart-details-avatar-title">
        {{ 'CART.avatarTitle' | translate }}
      </h5>
    </div>

    <h2 class="cart-details_total-title">
      {{ 'CART.yourOrder' | translate }}
    </h2>

    <div *ngIf="cart?.orderLines" class="cart-details_order-lines">
      <ol>
        <ng-container *ngFor="let orderLine of cart?.orderLines">
          <li>
            <app-cart-item [orderLine]="orderLine"
                           [isBusy]="isCartBusy"
                           [profile]="profile"
                           [dropShadow]="true"
                           (quantityClicked)="openBottomSheet($event)"
                           (fulfillmentSelected)="onFulfillmentSelected(orderLine, $event)"
                           (removeClicked)="removeOrderLine($event)"
                           [cultureName]="cultureName"
                           [currencyCode]="currencyCode"
                           [relatedProducts]="relatedProducts"
            ></app-cart-item>
          </li>
        </ng-container>
      </ol>
    </div>

    <ng-container *ngIf="this.currentAdjustmentType">
      <div class="adjustment-type">
        <h5 class="adjustment-type-title">
          Spis her eller ta med:
        </h5>
        <button class="adjustment-type-button"
                [ngStyle]="{backgroundColor: profile?.primaryColor, color: profile?.primaryTextColor}"
                (click)="requestAdjustment()">
          {{ getAdjustmentTypeTitle() }}
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="shoppingBag?.data?.length != 0">
      <div class="cart-details-shopping-bag" *ngIf="cart">
        <h5 class="cart-details-shopping-bag-title">
          {{ 'CART.SHOPPINGBAG.title' | translate }}
        </h5>
        <button class="cart-details-shopping-bag-button"
                [ngStyle]="{backgroundColor: profile?.primaryColor, color: profile?.primaryTextColor}"
                (click)="openShoppingBags()">
          {{ 'CART.SHOPPINGBAG.add' | translate }}
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="this.discountCodeEnabled">
      <div class="cart-details-shopping-bag" *ngIf="cart">
        <input [(ngModel)]="inputText" matInput class="discount-input"
               placeholder="{{ 'DISCOUNT.discount'  | translate }}">
        <button class="cart-details-shopping-bag-button"
                [ngStyle]="{backgroundColor: profile?.primaryColor, color: profile?.primaryTextColor}"
                (click)="addDiscount(inputText)">
          {{ 'DISCOUNT.add' | translate }}
        </button>
      </div>
    </ng-container>

    <div class="cart-details_total-summary" *ngIf="cart">
      <div>
        <h2 class="cart-details_total-title">
          {{ 'CART.sum' | translate }}
        </h2>
        <h5 class="cart-details_total-count">
          {{ 'CART.products' | translate:'{count: ' + getProductCount(cart) + '}' }}
        </h5>
      </div>
      <h2 class="cart-details_total-price">
        {{ toPrice(cart.sum) | currency:currencyCode:'symbol-narrow': '':cultureName }}
      </h2>
    </div>

    <ng-container *ngIf="requiresShipmentAddress">
      <div class="spacer">
        <svg width="100%" height="1">
          <line x1="0" y1="0.5" x2="100%" y2="0.499972" stroke="#DFE7EB" stroke-dasharray="6 6"></line>
        </svg>
      </div>
      <div class="edit-address">
        <button *ngIf="!cart?.shippingAddress"
                class="cart-details-shipping-button"
                (click)="openShipmentSheet()"
                [ngStyle]="{backgroundColor: profile?.primaryColor, color: profile?.primaryTextColor}">
          {{ 'SHIPPING.addAddress' | translate }}
        </button>
        <div *ngIf="cart?.shippingAddress" class="cart-details-address-group" (click)="openShipmentSheet()">
          <div class="cart-details-address-email">{{ cart?.shippingAddress?.emailAddress }}</div>
          <div class="cart-details-address-name">{{ cart?.shippingAddress?.fullName }}</div>
          <div>
            <span class="cart-details-address-addressOne">{{ cart?.shippingAddress?.addressLine1 }}, </span>
            <span class="cart-details-address-AddressTwo">{{ cart?.shippingAddress?.addressLine2 }}</span>
          </div>
          <div class="cart-details-pcc-group">
            <span class="cart-details-address-postalCode">{{ cart?.shippingAddress?.postalCode }} </span>
            <span class="cart-details-address-city">{{ cart?.shippingAddress?.city }}</span>
          </div>
          <div class="cart-details-address-country">{{ cart?.shippingAddress?.countryName }}</div>
          <div class="cart-details-address-change">
            <span>{{ 'SHIPPING.edit' | translate }}</span>
            <img src="/assets/images/edit.svg" alt="avatar image" height="20" width="20">
          </div>
        </div>
      </div>
    </ng-container>

    <div class="pay-button-container">
      <button *ngIf="!directPayment?.logoUrl"
              [disabled]="!isPaymentEnabled"
              class="pay-button"
              [ngStyle]="{backgroundColor: profile?.primaryColor, color: profile?.primaryTextColor, opacity : isPaymentEnabled ? 1 : 0.5}"
              (click)="goPayment()">
        {{ 'CART.paymentMethod' | translate }}
      </button>
      <button *ngIf="directPayment?.logoUrl" class="direct-payment-button" (click)="goPayment()" [disabled]="!isPaymentEnabled"
              [ngStyle]="{opacity : isPaymentEnabled ? 1 : 0.5}">
        <img class="direct-payment-logo" [src]="directPayment?.logoUrl" [alt]="directPayment?.name" width="250" height="55">
      </button>
    </div>
  </div>
</div>


