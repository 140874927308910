<div class="root">
  <ng-container *ngIf="orderLine != null, else loading" [ngSwitch]="orderLine.type">
    <div *ngSwitchCase="OrderLineType.Product" class="order-line product"
         [ngClass]="{'invalid': product?.isWeight && !hasValidWeight(orderLine)}">
      <ng-container *ngTemplateOutlet="productTemplate"></ng-container>
    </div>
    <div *ngSwitchCase="OrderLineType.Campaign" class="order-line campaign">
      <ng-container *ngTemplateOutlet="campaignTemplate"></ng-container>
    </div>
  </ng-container>
</div>

<ng-template #productTemplate>
  <div class="product-line">
    <app-product-item class="order-line_product-line"
                      [product]="product"
                      [orderLine]="orderLine"
                      [dropShadow]="dropShadow"
                      backgroundColor="transparent">
    </app-product-item>
    <ng-container *ngIf="(options?.length ?? 0) > 1">
      <button (click)="requestFulfillment()" class="product-item_button">
        <ng-container *ngIf="selectedFulfillmentOption, else unSelectedFulfillment">
          Collect: {{selectedFulfillmentOption.name}}
        </ng-container>
        <ng-template #unSelectedFulfillment>
          {{selectedFulfillmentOption?.name}}
        </ng-template>
      </button>
    </ng-container>
  </div>

  <div class="order-line_summary" *ngIf="product && !relatedProducts.includes(orderLine!.id) else relatedProductsTemplate">
    <ng-container *ngIf="orderLine && !asProductOrderLine(orderLine).weightInGrams, else weightAmountTemplate">
      <button class="order-line_summary-button"
              mat-raised-button
              [ngStyle]="{backgroundColor: profile?.primaryColor, color: profile?.primaryTextColor}"
              (click)="onQuantityClicked(orderLine)">
        {{ orderLine.quantity }}
      </button>
      <div class="order-line_summary-price">
        {{ toFriendlyPrice(orderLine!.unitPrice) | currency:currencyCode:'symbol-narrow': '':cultureName}}
      </div>
    </ng-container>
    <ng-template #weightAmountTemplate>
      <div class="order-line-summary_weight" (click)="hasValidWeight(orderLine!) ? onQuantityClicked(orderLine!) : remove(orderLine!)">
        {{ toFriendlyWeight(orderLine!) }}
        <ng-template [ngIf]="orderLine!.quantity > 1">x {{ orderLine?.quantity }}</ng-template>
      </div>
      <div class="order-line_summary-price small">
        {{ toFriendlyPrice(orderLine!.unitPrice) | currency:currencyCode:'symbol-narrow': '':cultureName}}
      </div>
    </ng-template>
  </div>

</ng-template>

<ng-template #campaignTemplate>
  <app-image-fading [source]="getCampaignImage(campaign?.image, 100)"
                    *ngIf="campaign?.image != null else saleIcon"
                    [isEager]="true"
                    class="order-line_image">
  </app-image-fading>
  <ng-template #saleIcon>
    <mat-icon class="order-line_image sale">
      money_off
    </mat-icon>
  </ng-template>

  <div class="order-line_details">
    <span>
      {{campaign?.name}}
    </span>
  </div>

  <div class="order-line_summary">
    <div class="order-line_summary-price">
      {{ toFriendlyPrice(orderLine!.totalPrice) | currency:currencyCode:'symbol-narrow': '':cultureName}}
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <app-gradient-loader></app-gradient-loader>
</ng-template>

<ng-template #relatedProductsTemplate>
  <div class="order-line_summary">
    <div class="order-line_summary-price" [ngStyle]="{'margin-top': '-15px'}">
      {{ toFriendlyPrice(orderLine!.totalPrice) | currency:currencyCode:'symbol-narrow': '':cultureName}}
    </div>
  </div>
</ng-template>
