export class FormatUtils {
  static toFriendlyPrice(price: number): string {
    return this.replaceEmptyDecimalsIfPossible((price / 100).toFixed(2).toString());
  }

  static toFriendlyWeight(weightInGrams: number): string {
    const weight = parseFloat((weightInGrams / 1000).toFixed(2)).toString();
    return this.replaceEmptyDecimalsIfPossible(weight);
  }

  private static replaceEmptyDecimalsIfPossible(num: string): string {
    return num.endsWith('00') ? `${num.slice(0, -3)}` : num;
  }
}
