import {Component, OnInit} from '@angular/core';
import {ForegroundPaths} from '../../../app-routing.module';
import {ActivatedRoute, Router} from '@angular/router';
import {StoreProfile} from '../../../domain/models/store/store-profile';
import {Cart} from '../../../domain/models/order/cart';
import {Store} from '../../../domain/models/store/store';
import {StoreService} from '../../../domain/store.service';
import {ProductService} from '../../../domain/product.service';
import {CartService} from '../../../domain/cart.service';
import {Product} from '../../../domain/models/product/product';
import {Paginated} from '../../../transport/models/paginated';
import {Image} from '../../../domain/models/product/image';
import {ImageUtils} from '../../../utils/image.utils';
import {Dimension} from '../../../domain/models/product/dimension';
import {FormatUtils} from '../../../utils/format.utils';

@Component({
  selector: 'app-shopping-bag',
  templateUrl: './shopping-bag.component.html',
  styleUrls: ['./shopping-bag.component.sass'],
})
export class ShoppingBagComponent implements OnInit {
  store?: Store;
  cart?: Cart;
  currencyCode?: string;
  cultureName?: string;
  isBusy = false;
  profile?: StoreProfile;
  shoppingBag?: Paginated<Product>;

  constructor(private router: Router,
              private storeService: StoreService,
              private route: ActivatedRoute,
              private productService: ProductService,
              private cartService: CartService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    const storeHandle = this.route.parent!.firstChild!.snapshot.paramMap.get('id')!;
    this.store = await this.storeService.getStore(storeHandle);
    this.profile = this.store.storeProfile;
    this.cultureName = this.store.cultureName;
    this.currencyCode = this.store.currencyCode;
    this.shoppingBag = await this.productService.getShoppingBagProducts(storeHandle);
  }

  async closeForeground() {
    await this.router.navigate(ForegroundPaths.cart());
  }

  getProductImage(images: Image[] | undefined, size: number): string | undefined {
    if (images != undefined) {
      return ImageUtils.getImageUrl(images[0], size);
    }
    return undefined;
  }

  async openProductDetails(producthandle: string) {
    if (producthandle != null) {
      await this.router.navigate(ForegroundPaths.product(producthandle));
    }
  }

  async addToCart(product: Product, dimension?: Dimension): Promise<any> {
    this.isBusy = true;
    if (this.store != null) {
      await this.router.navigate(ForegroundPaths.cart());
      await new Promise(resolve => setTimeout(resolve, 80));
      await this.cartService.add(this.store.handle, product, dimension?.id);
    }
    this.isBusy = false;
  }

  toFriendlyPrice(price: number): string {
    return FormatUtils.toFriendlyPrice(price);
  }
}
