import {AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {foregroundOutletName, ForegroundPaths} from './app-routing.module';
import {ScannerService} from './domain/scanner.service';
import {ScannerComponent} from './components/scanner/scanner.component';
import {Observable, Subject, Subscription} from 'rxjs';
import {LocalStorageService} from 'ngx-webstorage';
import {TranslateService} from '@ngx-translate/core';
import {GTagService} from './domain/gtag.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(RouterOutlet) private outlets?: QueryList<RouterOutlet>;
  @ViewChild(ScannerComponent) private scanner?: ScannerComponent;

  isForegroundOpen = false;
  canCloseForeground = false;

  requirePrivacyConsent = false;

  private foregroundSubscription?: Subscription;
  private static foregroundChanged = new Subject<boolean>();

  constructor(private router: Router,
              private scannerService: ScannerService,
              private localStorageService: LocalStorageService,
              private gTagService: GTagService,
              translate: TranslateService) {

    translate.addLangs(['en', 'no', 'de']);
    translate.setDefaultLang('en');
    const browserLang = translate.getBrowserLang().replace(/nb|nn/, 'no');
    translate.use(browserLang.match(/en|no|de|sv/) ? browserLang : 'en');

    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        this.gTagService.pageView(this.router.url, window.location.href);
      }
    });
  }

  ngOnInit(): void {
    if (!this.localStorageService.retrieve('privacy_preference')) {
      this.requirePrivacyConsent = true;
    } else {
      // @ts-ignore
      window['ga-disable-G-KJYQK1R821'] = false;
    }
  }

  async ngAfterViewInit() {
    this.scannerService.registerScanner(this.scanner!);
  }

  ngOnDestroy(): void {
    this.foregroundSubscription?.unsubscribe();
  }

  foregroundActivated(): Promise<any> {
    return new Promise(resolve => setTimeout(resolve, 50)).then(
      _ => {
        const cardOutlet = this.outlets!.find(x => x.activatedRoute.snapshot.outlet === foregroundOutletName);
        if (cardOutlet != null) {
          this.foregroundSubscription?.unsubscribe();
          this.foregroundSubscription = this.router.events.subscribe(_ => {
            this.onForegroundChanged();
          });
        } else {
          this.isForegroundOpen = false;
        }
        this.onForegroundChanged();
      },
    );
  }

  async closeForeground() {
    if (this.canCloseForeground) {
      await this.router.navigate(ForegroundPaths.empty());
      this.isForegroundOpen = false;
    }
  }

  hasForeground(): boolean {
    const excludeFromRoutes = ['/receipt/', '/orders', '/privacy-policy'];
    return this.router.url.includes('/store/') && !excludeFromRoutes.some(r => this.router.url.includes(r));
  }

  private onForegroundChanged() {
    const cardOutlet = this.outlets!
      .filter(x => x.isActivated)
      .find(x => x.activatedRoute.snapshot.outlet === foregroundOutletName);
    if (cardOutlet) {
      const isForegroundOpen = cardOutlet.activatedRoute.snapshot.url.length !== 0;
      const isAdjustmentChooserPage = this.router.url.includes('choose');
      const isReceiptPage = this.router.url.includes('receipt');
      this.canCloseForeground = isForegroundOpen && !isAdjustmentChooserPage && !isReceiptPage;
      if (this.isForegroundOpen != isForegroundOpen) {
        this.isForegroundOpen = isForegroundOpen;
        AppComponent.isForegroundOpen = isForegroundOpen;
        AppComponent.foregroundChanged.next(this.isForegroundOpen);
      }
    }
  }

  static onForegroundChanged(): Observable<boolean> {
    return this.foregroundChanged;
  }

  static isForegroundOpen = false;
}
