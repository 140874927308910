import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Order} from '../../domain/models/order/order';
import {FormatUtils} from '../../utils/format.utils';
import {OrderUtils} from '../../utils/order.utils';
import {OrderLineType, ProductOrderLine} from 'src/app/domain/models/order/order-line';
import {Store} from '../../domain/models/store/store';
import {OrderLine} from '../../domain/models/order/cart';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.sass'],
})
export class ReceiptComponent implements OnChanges {
  readonly OrderLineType = OrderLineType;

  @Input() order?: Order;
  @Input() store?: Store;
  @Input() showOuterLines? = true;

  currencyCode?: string;
  cultureName?: string;
  hasTax = true;
  isPaid = false;
  hasPendingDelivery = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.store && this.store) {
      this.currencyCode = this.store.currencyCode;
      this.cultureName = this.store.cultureName;
    }

    if (changes.order && this.order) {
      this.hasTax = this.order.taxSum >= 1;
      this.isPaid = OrderUtils.isPaid(this.order);
      this.hasPendingDelivery = this.isPaid && OrderUtils.isPendingDelivery(this.order);
    }
  }

  toPrice(price: number): string {
    return FormatUtils.toFriendlyPrice(price);
  }

  toFriendlyWeight(productOrderLine: OrderLine): string {
    const line = productOrderLine as ProductOrderLine;
    return line.weightInGrams ? `${FormatUtils.toFriendlyWeight(line.weightInGrams)} kg` : '? kg';
  }

  asProductOrderLine(orderLine: OrderLine): ProductOrderLine {
    return orderLine as ProductOrderLine;
  }
}
