import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {OrderLine} from '../../domain/models/order/cart';
import {OrderLineType, ProductOrderLine} from '../../domain/models/order/order-line';
import {Product} from '../../domain/models/product/product';
import {ImageUtils} from '../../utils/image.utils';
import {StoreProfile} from '../../domain/models/store/store-profile';
import {FormatUtils} from '../../utils/format.utils';
import {Dimension} from '../../domain/models/product/dimension';
import {ProductService} from '../../domain/product.service';
import {ActivatedRoute} from '@angular/router';
import {Image} from '../../domain/models/product/image';
import {Campaign} from '../../domain/models/campaign/campaign';
import {SelectFulfilmentDialog} from '../../dialogs/select-fulfilment/select-fulfillment-dialog';
import {FulfillmentOption} from '../../domain/models/store/fulfillmentOption';
import {MatDialog} from '@angular/material/dialog';
import {StoreService} from '../../domain/store.service';
import {CartUtils} from '../../utils/cart.utils';

@Component({
  selector: 'app-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.sass']
})
export class CartItemComponent implements OnInit, OnChanges {
  OrderLineType = OrderLineType;

  @Input() orderLine?: OrderLine;
  @Input() profile?: StoreProfile;
  @Input() isBusy: boolean = false;
  @Input() dropShadow: boolean = false;
  @Input() currencyCode: string | undefined;
  @Input() cultureName: string | undefined;
  @Input() relatedProducts: Array<string> = [];
  options?: FulfillmentOption[];

  @Output() quantityClicked = new EventEmitter<ProductOrderLine>();
  @Output() removeClicked = new EventEmitter<ProductOrderLine>();
  @Output() fulfillmentSelected = new EventEmitter<FulfillmentOption>();
  product?: Product;
  dimension?: Dimension;
  campaign?: Campaign;
  selectedFulfillmentOption?: FulfillmentOption;

  constructor(private route: ActivatedRoute,
              private productService: ProductService,
              private storeService: StoreService,
              private dialog: MatDialog) {
  }

  async ngOnInit() {
    const storeHandle = this.route.parent!.firstChild!.snapshot.paramMap.get('id')!;
    if (this.orderLine != null) {
      switch (this.orderLine.type) {
        case OrderLineType.Product:
          const selected = this.orderLine.product;
          const store = await this.storeService.getStore(storeHandle);
          this.product = await this.productService.getProductById(storeHandle, selected.id);
          this.dimension = this.product.dimensions.find(dim => dim.id === selected.dimensionId);
          this.options = store.fulfillmentOptions.filter(x => this.product?.fulfillmentOptions?.find(y => x.id == y.id));
          const orderLineFulfillmentId = this.orderLine.fulfillmentOptionId;
          if (orderLineFulfillmentId) {
            this.selectedFulfillmentOption = this.options.find(x => x.id == orderLineFulfillmentId);
          }
          break;
        case OrderLineType.Campaign:
          this.campaign = this.orderLine.campaign;
          break;
      }
    } else {
      this.product = undefined;
      this.dimension = undefined;
      this.campaign = undefined;
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    await this.ngOnInit();
  }

  getCampaignImage(image: Image | undefined, size: number): string {
    if (image != null) {
      return ImageUtils.getImageUrl(image, size) ?? ImageUtils.fallbackImage;
    }
    return ImageUtils.fallbackImage;
  }

  toFriendlyPrice(price: number): string {
    return FormatUtils.toFriendlyPrice(price);
  }

  toFriendlyWeight(productOrderLine: OrderLine): string {
    const line = productOrderLine as ProductOrderLine;
    return line.weightInGrams ? `${FormatUtils.toFriendlyWeight(line.weightInGrams)} kg` : '? kg';
  }

  onQuantityClicked(orderLine: OrderLine) {
    this.quantityClicked.emit(orderLine as ProductOrderLine);
  }

  async requestFulfillment() {
    const result = await this.dialog.open(SelectFulfilmentDialog, {
      width: '250px',
      data: this.options
    }).afterClosed().toPromise();
    const selected = result as FulfillmentOption;
    if (selected) {
      const productOrderLine = this.orderLine as ProductOrderLine;
      if (productOrderLine) {
        this.selectedFulfillmentOption = selected;
        this.fulfillmentSelected.emit(selected);
      }
    }
  }

  remove(orderLine: OrderLine) {
    const productOrderLine = orderLine as ProductOrderLine;
    if (productOrderLine) {
      this.removeClicked.emit(productOrderLine);
    }
  }

  hasValidWeight(productOrderLine: OrderLine): boolean {
    return productOrderLine.type === OrderLineType.Product ? CartUtils.isValidOrderLine(productOrderLine) : false;
  }

  asProductOrderLine(orderLine: OrderLine): ProductOrderLine {
    return orderLine as ProductOrderLine;
  }
}
