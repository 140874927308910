import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {StoreProfile} from '../../domain/models/store/store-profile';

@Component({
  templateUrl: './weight-input.dialog.html',
  styleUrls: ['./weight-input.dialog.sass'],
})
export class WeightInputDialog implements OnInit {
  inputText?: string;
  profile?: StoreProfile;

  constructor(public dialogRef: MatDialogRef<WeightInputDialog, number | null>,
              @Inject(MAT_DIALOG_DATA) public data: { unit?: string, storeProfile?: StoreProfile },
  ) {
  }

  async ngOnInit() {
    this.profile = this.data.storeProfile;
  }

  close(weight?: string) {
    return this.dialogRef.close(weight ? Number.parseInt(weight, 10) : null);
  }
}
