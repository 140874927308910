/**
 * GS1 barcodes
 * @see: https://gs1.se/en/support/how-do-i-create-my-variable-weight-numbers/
 */
export class Gs1Utils {
  static generateWeightBarcode(productBarcode: string, weightInGrams: number): string {
    let weightCode = 0;
    let weight = '';

    if (!this.isValidProductBarcode(productBarcode)) {
      throw Error('Invalid barcode length. Should be 6 characters');
    }

    if (weightInGrams > 0 && weightInGrams <= 9999) {
      weightCode = 23;
      weight = weightInGrams.toString();
    } else if (weightInGrams > 9999 && weightInGrams <= 99999) {
      weightCode = 24;
      weight = weightInGrams.toString().slice(0, -1);
    } else if (weightInGrams > 99999 && weightInGrams <= 999999) {
      weightCode = 25;
      weight = weightInGrams.toString().slice(0, -2);
    }

    if (!weight || !weightCode) {
      throw Error('Invalid weight');
    }

    return `${weightCode}${productBarcode}${weight.padStart(4, '0')}8`;
  }

  static isValidProductBarcode(barcode?: string): boolean {
    return barcode?.length == 6;
  }
}
