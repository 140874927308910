import {Campaign} from '../campaign/campaign';

interface OrderLine {
  id: string;
  type: OrderLineType;
  name: string;
  quantity: number;
  unitPrice: number;
  unitTaxPaid: number;
  totalPrice: number;
  totalTaxPaid: number;
  taxPercent: number;
}

export interface ProductOrderLine extends OrderLine {
  type: OrderLineType.Product;
  product: SelectedProduct;
  itemIds: string[];
  fulfillmentOptionId?: string;
  weightInGrams?: number;
  scannedBarcode?: string;
}

export interface CampaignOrderLine extends OrderLine {
  type: OrderLineType.Campaign;
  campaign: Campaign;
}

export interface SelectedProduct {
  id: string;
  dimensionId: string;
  isWeight: boolean;
}

export enum OrderLineType {
  Product = 'product',
  Campaign = 'campaign',
}
