import {Component, OnDestroy, OnInit} from '@angular/core';
import {StoreProfile} from '../../../domain/models/store/store-profile';
import {Cart} from '../../../domain/models/order/cart';
import {ActivatedRoute, Router} from '@angular/router';
import {StoreService} from '../../../domain/store.service';
import {CartService} from '../../../domain/cart.service';
import {CampaignOrderLine, OrderLineType, ProductOrderLine} from '../../../domain/models/order/order-line';
import {Store} from '../../../domain/models/store/store';
import {Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialog} from '../../../dialogs/confirm/confirm.dialog';
import {TranslateService} from '@ngx-translate/core';
import {FormatUtils} from '../../../utils/format.utils';
import {PaymentMethod} from '../../../domain/models/payment/payment-method';
import {PaymentService} from '../../../domain/payment.service';
import {ForegroundPaths} from '../../../app-routing.module';
import {AmountsheetDialog} from '../../../dialogs/actionsheet/amountsheet/amountsheet.dialog';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {ShipmentsheetDialog} from '../../../dialogs/actionsheet/shipmentsheet/shipmentsheet.dialog';
import {CartUtils} from '../../../utils/cart.utils';
import {ShippingAddress} from '../../../domain/models/order/shipping-address';
import {FulfillmentOption} from '../../../domain/models/store/fulfillmentOption';
import {ProductService} from '../../../domain/product.service';
import {Paginated} from '../../../transport/models/paginated';
import {Product} from '../../../domain/models/product/product';
import {AdjustmentType} from '../../../domain/models/product/adjustmentType';
import {AdjustmentTypeDialog} from '../../../dialogs/adjustment-type/adjustment-type-dialog';
import {QuantityConfirmDialog} from '../../../dialogs/quantity-confirm/quantity-confirm.dialog';
import {AdjustmentTypeService} from '../../../domain/adjustment-type.service';
import {OrderService} from '../../../domain/order.service';
import {CustomerService} from '../../../domain/customer.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-cart',
  templateUrl: './cart-details.component.html',
  styleUrls: ['./cart-details.component.sass'],
})
export class CartDetailsComponent implements OnInit, OnDestroy {
  cart?: Cart;
  profile?: StoreProfile;
  store?: Store;
  inputText?: string;
  isCartBusy = false;
  requiresShipmentAddress = false;
  currencyCode?: string;
  cultureName?: string;
  shoppingBag?: Paginated<Product>;
  currentAdjustmentType: AdjustmentType | undefined;
  discountCodeEnabled = false;
  private counter: Array<string> = [];
  relatedProducts: Array<string> = [];
  directPayment?: PaymentMethod;
  isPaymentEnabled = false;

  private cartChangeSubscription: Subscription | undefined;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private storeService: StoreService,
              private orderService: OrderService,
              private cartService: CartService,
              private productService: ProductService,
              private paymentService: PaymentService,
              private dialogService: MatDialog,
              private customerService: CustomerService,
              private translateService: TranslateService,
              private bottomSheet: MatBottomSheet,
              private dialog: MatDialog,
              private adjustmentService: AdjustmentTypeService,
              private toastr: ToastrService,
  ) {
  }

  async ngOnInit() {
    if (!this.router.url.includes('/store/')) {
      return;
    }
    const storeHandle = this.route.parent!.firstChild!.snapshot.paramMap.get('id')!;
    this.store = await this.storeService.getStore(storeHandle);
    const store = this.store;
    this.profile = this.store.storeProfile;

    const directPaymentOption = await this.paymentService.getDirectPaymentOption(this.store.id);
    if (directPaymentOption) {
      this.directPayment = directPaymentOption;
    }

    const cartParam = this.route.snapshot.queryParams['orderId'];
    const customerIdParam = this.route.snapshot.queryParams['custId'];

    if (cartParam !== undefined) {
      await this.customerService.customerAnonymousAuthenticate(this.store.storeChain.id, customerIdParam);
      const order = await this.orderService.getOrder(cartParam);
      await this.cartService.setCart(storeHandle, order);
    }

    this.setCart(await this.cartService.getOrCreateLocalCart(storeHandle), store);
    this.cartChangeSubscription = this.cartService
      .onCartChanges(storeHandle)
      .subscribe(value => this.setCart(value, store));
    this.currencyCode = this.store?.currencyCode;
    this.cultureName = this.store?.cultureName;
    this.shoppingBag = await this.productService.getShoppingBagProducts(storeHandle);
    await this.checkAdjustmentType();
    await this.getDiscountSetting();
  }

  ngOnDestroy(): void {
    this.cartChangeSubscription?.unsubscribe();
  }

  private setCart(cart: Cart, store: Store) {
    if (cart) {
      this.isPaymentEnabled = !!(cart.orderId && cart.orderLines.length > 0);
      this.requiresShipmentAddress = CartUtils.requiresShipmentAddress(store, cart);
      this.cart = cart;
      this.checkForDepositFeeProducts();
    } else {
      this.isPaymentEnabled = false;
    }
  }

  toPrice(price: number): string {
    return FormatUtils.toFriendlyPrice(price);
  }

  getProductCount(cart?: Cart): number {
    return cart?.orderLines
      .filter(line => line.type === OrderLineType.Product)
      .map(line => line as ProductOrderLine)
      .map(line => line.quantity)
      .reduce((sum, q) => sum + q, 0) ?? 0;
  }

  private async confirmRemoval(): Promise<boolean> {
    const text = await this.translateService.get('DIALOG.REMOVEPRODUCT.content').toPromise();
    const confirm = await this.translateService.get('DIALOG.REMOVEPRODUCT.submit').toPromise();
    const dialogRef = this.dialogService.open(ConfirmDialog, {
      width: '250px',
      data: {
        text,
        confirm,
      },
    });
    const result = await dialogRef.afterClosed().toPromise();
    return result === true;
  }

  async openBottomSheet(orderLine: ProductOrderLine) {
    const productNameSelectedForQuantityEdit = orderLine.name;
    this.counter.push(productNameSelectedForQuantityEdit);
    const numberOfTimesQuantityEdit = this.countInArray(this.counter, productNameSelectedForQuantityEdit);
    const quantityBeforeEdit = orderLine.quantity;
    let result = await this.bottomSheet.open(AmountsheetDialog, {
      data: orderLine.quantity,
    }).afterDismissed().toPromise();
    if (result < quantityBeforeEdit - 2 || numberOfTimesQuantityEdit > 3) {
      result = await this.dialog.open(QuantityConfirmDialog, {
        data: orderLine.quantity,
      }).afterClosed().toPromise();
    }
    if (result == null || typeof result !== 'number') {
      return;
    }
    const quantity = result as number;
    if (this.store?.handle != null) {
      if (quantity === 0) {
        await this.cartService.remove(this.store.handle, orderLine);
        await this.ngOnInit();
      }
      if (quantity > 0) {
        await this.cartService.setCount(this.store.handle, orderLine, result);
      }
    }
  }

  async openShipmentSheet() {
    const result = await this.bottomSheet.open(ShipmentsheetDialog, {
        data: this.cart?.shippingAddress,
        panelClass: 'full-screen-bottom-sheet',
      },
    ).afterDismissed().toPromise();
    if (result as ShippingAddress && this.cart) {
      this.cart.shippingAddress = result;
      if (this.store?.handle && result != null) {
        await this.cartService.setShipmentAddress(this.store?.handle, result);
      }
    }
  }

  async onFulfillmentSelected(orderLine: ProductOrderLine | CampaignOrderLine, fulfillmentOption: FulfillmentOption) {
    if (orderLine as ProductOrderLine) {
      await this.cartService.setFulfillmentOptions(this.store?.handle!, orderLine as ProductOrderLine, fulfillmentOption);
    }
  }

  async removeOrderLine(orderLine: ProductOrderLine) {
    const confirmed = await this.confirmRemoval();
    if (confirmed && this.store) {
      await this.cartService.remove(this.store.handle, orderLine);
      await this.ngOnInit();
    }
  }

  async openShoppingBags() {
    await this.router.navigate(ForegroundPaths.shoppingBag());
  }

  async addDiscount(discountCode: string | undefined) {
    await this.cartService.setDiscount(this.store?.handle!, discountCode);
  }

  async checkAdjustmentType() {
    const storedAdjustmentTypeId = this.adjustmentService.getStoredTypeId(this.store!.handle);
    if (storedAdjustmentTypeId != null) {
      const adjustmentType = this.store?.adjustmentTypes.find(at => at.id === storedAdjustmentTypeId);
      if (adjustmentType !== undefined) {
        this.currentAdjustmentType = adjustmentType;
      }
    } else {
      if (this.store?.adjustmentTypes != undefined && this.store?.adjustmentTypes.length > 0) {
        await this.requestAdjustment();
      }
    }
  }

  async requestAdjustment() {
    const result = await this.dialogService.open(AdjustmentTypeDialog, {
      width: '250px',
      data: this.store?.adjustmentTypes,
      disableClose: !this.cart?.adjustmentTypeId,
    }).afterClosed().toPromise();

    if (!result) {
      return;
    }

    const selected = result as AdjustmentType;
    await this.cartService.setAdjustmentType(this.store!.handle!, selected!);
    await this.cartService.refreshCart(this.store!.handle);
    this.setCart(await this.cartService.getOrCreateLocalCart(this.store!.handle), this.store!);
    this.currentAdjustmentType = selected!;
  }

  getAdjustmentTypeTitle() {
    if (this.currentAdjustmentType?.name == 'TakeAway') {
      return 'Ta med';
    } else if (this.currentAdjustmentType?.name == 'EatIn') {
      return 'Spis her';
    }
    return undefined;
  }

  countInArray(array: string | any[], what: any) {
    var count = 0;
    for (var i = 0; i < array.length; i++) {
      if (array[i] === what) {
        count++;
      }
    }
    return count;
  }

  private async getDiscountSetting() {
    try {
      this.discountCodeEnabled = await this.storeService.isDiscountCodeEnabled(this.store!.handle);
    } catch (error) {
      if (error.status == 404) {
        this.discountCodeEnabled = false;
      } else {
        throw error;
      }
    }
  }

  async goPayment() {
    if (this.cart && await this.cartService.getCartCount(this.cart) !== '0') {
      await this.router.navigate(ForegroundPaths.cartPayment());
    } else {
      this.toastr.warning(
        await this.translateService.get('CART.empty').toPromise(),
        undefined,
        {timeOut: 3000, easeTime: 100, positionClass: 'toast-top-center'},
      );
    }
  }

  checkForDepositFeeProducts() {
    const relatedProducts: string[] = [];
    this.cart?.orderLineRelations.forEach(function(value) {
      relatedProducts.push(value.relatedOrderLineId);
    });
    this.relatedProducts = relatedProducts;
  }
}
