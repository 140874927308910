<div class="weight-input-page-close">
  <mat-icon (click)="close()">
    close
  </mat-icon>
</div>
<div class="weight-input-dialog-title">
  {{ 'DIALOG.WEIGHTINPUT.enterWeight' | translate }}
</div>
<div class="weight-input-dialog-product">
  {{ 'DIALOG.WEIGHTINPUT.enterWeightIn' | translate }} {{ 'DIALOG.WEIGHTINPUT.' + data.unit | translate }}:
</div>
<mat-form-field appearance="fill">
  <input [(ngModel)]="inputText" matInput placeholder="1234" type="number" inputmode="numeric" pattern="^\d+$" required>
</mat-form-field>
<div class="weight-input-add-button"
     [ngStyle]="{backgroundColor: profile?.primaryColor, color: profile?.primaryTextColor, opacity: inputText ? 1 : 0.8}"
     (click)="inputText && close(inputText)">
  {{ 'PRODUCTDETAILS.addToCart' | translate }}
</div>
<h4 class="weight-input-dialog_action weight-input-dialog_cancel" (click)="close()">
  {{ 'DIALOG.cancel' | translate }}
</h4>
